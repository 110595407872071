import Home from './pages/home';

function App() {
    return (    
      <div className="App overflow-hidden">
        <header className="App-header">
          <Home />
        </header>
      </div>
    );
  };

export default App;
