import React, { useState } from 'react';
import checkImage from '../../images/check.svg';
import noCheckImage from '../../images/no-check.svg';
import information from '../../images/information_check.svg';
import noInformation from '../../images/information_no_check.svg'
import './ServiceItem.css';

const ServiceItem = ({ title, validated, info }) => {
  const [hover, setHover] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // Nouvel état pour l'overlay

  const renderInfo = (info) => {
    return info.split('<br><br>').map((paragraph, index) => (
      <div key={index} className="info-paragraph mb-4">
        {paragraph.split('<br>').map((line, idx) => (
          <p key={idx} className="font-clash-500 info-line">
            {line.split(/(\(.*?\))/).map((part, i) => 
              part.startsWith('(') && part.endsWith(')') ? (
                <span key={i} className="font-clash-400">{part}</span>
              ) : (
                part
              )
            )}
          </p>
        ))}
      </div>
    ));
  };

  return (
    <>      
        {showOverlay && <div className="overlay"></div>} {/* Conditionnellement afficher l'overlay */}
        <li className="relative flex items-center justify-between gap-x-4">
            <div className='flex flex-row gap-x-2'>
                {
                    validated ? 
                        <img src={checkImage} alt="Check" /> : 
                        <img src={noCheckImage} alt="No Check" />
                }
                <span className={validated ? 'class-valid text-base xl:text-xl font-clash-500 text-left' : 'class-invalid text-gray-tn text-left text-base xl:text-xl font-clash-500'}>{title}</span>
            </div>
            
            <div className="cursor-pointer relative info-icon" 
                onMouseEnter={() => { 
                    if (validated) {
                        setHover(true); 
                        setShowOverlay(true);
                    }
                }} 
                onMouseLeave={() => { 
                    if (validated) {
                        setHover(false); 
                        setShowOverlay(false);
                    }
                }}>
                {
                    validated ? 
                        <img src={information} alt="Check" /> : 
                        <img src={noInformation} alt="No Check"/>
                }
                { hover && info && validated && (
                    <div className="info-tooltip">
                        {renderInfo(info)}
                    </div>
                )}
            </div>
        </li>
    </>
  );
};

export default ServiceItem;