import React from 'react';
import './Button.css';

const Button = ({ label, styleType, onClick, link, blank }) => {
  let className = 'button ';

  switch (styleType) {
    case 'white':
      className += 'button-white';
      break;
    case 'blue':
      className += 'button-blue';
      break;
    case 'grey':
      className += 'button-grey';
      break;
    default:
      className += 'button-white';
  }

  if (link) {
    return (
      <a
        href={link}
        className={className + ' font-clash-700 text-xl xl:text-2xl'}
        target={blank ? '_blank' : '_self'}
        rel={blank ? 'noopener noreferrer' : ''}
        onClick={styleType === 'grey' ? (e) => e.preventDefault() : null}
      >
        {label}
      </a>
    );
  }

  return (
    <button className={className} onClick={styleType !== 'grey' ? onClick : null}>
      {label}
    </button>
  );
};

export default Button;
