import React from 'react';
import ServiceItem from '../composants/ServiceItem/ServiceItem';
import Button from '../composants//Button/Button';
import './PricingTable.css';

const Home = () => {

  const servicesIdentity = [
    { title: "Univers graphique pour l'ensemble de vos matchs", validated: true, info: `- Template "Annonce du groupe" <br><br>- Template "Composition d'équipe" <br><br> - Kit digital "Live Match" : Divers templates pour illustrer les moments clés de vos matchs selon vos besoins <br>(coup d’envoi, mi-temps, fin du match, but, essai, transformation, changement...).` },
    { title: "3 contenus graphiques personnalisés", validated: true, info: "Création de visuels, adaptés à vos besoins (possibilité de développer des templates)" },
    { title: "Template Social Media", validated: true, info: `- Template "Prochain match"<br>- Template "Joueur du mois"<br>- Template "Anniversaire"` },
    { title: "Délinaisons de l'univers graphique pour les matchs à l'extérieur", validated: false, info: "Déclinaisons de l’univers graphique aux couleurs de vos maillots extérieurs." },
    { title: "Promotion des matchs du mois à venir", validated: false, info: `Réflexion et création d’un visuel par mois pour annoncer les matchs importants en lien avec l’actualité.`},
    { title: "Création de 10 affiches de matchs", validated: false, info : `Chaque affiche se distingue par un univers graphique unique, conçu pour engager les fans et souligner l’importance du match.` },
    { title: "Motion design de vos LEDS et écrans géants", validated: false, info: `Animation "Présentation d'équipe"<br> animation "BUT" pour tout votre effectif en début de saison.` },
    { title: "Réalisation d'un covering", validated: false }
  ];

  const servicesCreativeIdentity = [
    { title: "Univers graphique pour les matchs à domicile", validated: true, info: `- Template "Annonce du groupe" <br><br>- Template "Composition d'équipe" <br><br> - Kit digital "Live Match" : Divers templates pour illustrer les moments clés de vos matchs selon vos besoins <br>(coup d’envoi, mi-temps, fin du match, but, essai, transformation, changement...).` },
    { title: "5 contenus graphiques personnalisés", validated: true, info: "Création de visuels, adaptés à vos besoins (possibilité de développer des templates)" },
    { title: "Template Social Media", validated: true, info: `- Template "Prochain match"<br>- Template "Joueur du mois"<br>- Template "Anniversaire"` },
    { title: "Délinaisons de l'univers graphique pour les matchs à l'extérieur", validated: true, info: "Déclinaisons de l’univers graphique aux couleurs de vos maillots extérieurs." },
    { title: "Promotion des matchs du mois à venir", validated: true, info: "Réflexion et création d’un visuel par mois pour annoncer les matchs importants <br> en lien avec l’actualité." },
    { title: "Création de 10 affiches de matchs", validated: true, info: `Chaque affiche se distingue par un univers graphique unique, conçu pour engager les fans et souligner l’importance du match.`},
    { title: "Motion design de vos LEDS et écrans géants", validated: false, info: `- Animation "Présentation d'équipe"<br><br>- Animation "BUT" pour tout votre effectif en début de saison.` },
    { title: "Réalisation d'un covering", validated: false }
  ];

  const servicesCreativeMotionIdentity = [
    { title: "Univers graphique pour les matchs à domicile", validated: true, info: `- Template "Annonce du groupe" <br><br>- Template "Composition d'équipe" <br><br>- Kit digital "Live Match" : Divers templates pour illustrer les moments clés de vos matchs selon vos besoins <br>(coup d’envoi, mi-temps, fin du match, but, essai, transformation, changement...).` },
    { title: "5 contenus graphiques personnalisés", validated: true, info: "Création de visuels, adaptés à vos besoins (possibilité de développer des templates)" },
    { title: "Template Social Media", validated: true, info: `- Template "Prochain match"<br>- Template "Joueur du mois"<br>- Template "Anniversaire"` },
    { title: "Délinaisons de l'univers graphique pour les matchs à l'extérieur", validated: true, info: "Déclinaisons de l’univers graphique aux couleurs de vos maillots extérieurs." },
    { title: "Promotion des matchs du mois à venir", validated: true, info: `Réflexion et création d’un visuel par mois pour annoncer les matchs importants en lien avec l’actualité.`},
    { title: "Création de 20 affiches de matchs", validated: true, info: `Chaque affiche se distingue par un univers graphique unique, conçu pour engager les fans et souligner l’importance du match.` },
    { title: "Motion design de vos LEDS et écrans géants", validated: true, info: `- Animation "Présentation d'équipe"<br><br>- Animation "BUT" pour tout votre effectif en début de saison.` },
    { title: "Réalisation d'un covering", validated: true, info: "Création de covering pour bus, façades extérieures, panneaux autour du terrain et autres supports en fonction de vos besoins." }
  ];

  return (
    <div className="pricing-table">
        <div className="pricing_title relative mb-10">
            <h1 className="text-base md:text-4xl font-bold font-panchang leading-7 xl:leading-10 max-w-[1100px] mx-auto xl:mt-14 p-4">
                <span className='text-blue-tn'>UNE CRÉATIVITÉ</span> SANS LIMITE, POUR UNE <span className='text-blue-tn'>VICTOIRE ASSURÉE</span>
            </h1>
        </div>
      <div className="pricing-plans">
        <div className="plan_left">
            <div className='plan_left_head bg-gray-light-tn border-b border-black p-4 text-left'>
                <h2 className='font-clash-700 text-xl xl:text-2xl text-blue-tn mb-4'>Identity</h2>
                <div className='h-[32px] xl:h-[40px]'></div>
                <p className="text-4xl xl:text-6xl font-clash-700 text-blue-tn mb-2">4 500€</p>
                <p className="text-gray-tn font-clash-400 text-xl xl:text-2xl mb-4">Sans engagement</p>
            </div>
            <div className='content_services border-b border-black mx-4 mt-8'>
              <ul>
                {servicesIdentity.map((service, index) => (
                  <ServiceItem key={index} title={service.title} validated={service.validated} info={service.info} />
                ))}
              </ul>
            </div>
          <div className="includes">
            <div className='includes_title'>
              <p className='font-clash-700 text-xl'>Inclut:</p>
            </div>
            <div className='includes_content'>
                <ul className='ml-4'>
                  <li className='font-clash-500'>Moodboard & Direction Artistique</li>
                  <li className='font-clash-500'>Allers-retours illimités pour chaque maquette</li>
                  <li className='font-clash-500'>2 formats déclinés <span className='font-clash-400 text-sm'>(4:5 et 9:16 ou 1:1)</span></li>
                </ul>
            </div>
          </div>
          <div className='mb-8'>
            <Button label="Prendre RDV" styleType="white" link="https://calendly.com/nestour/appel-decouverte" blank={true} />
          </div>
          <p className="availability relative font-clash-500 color-black mb-6">2 places restantes</p>
        </div>
        <div className="plan_middle popular relative">
            <div className="poular_tag">
                <p className="font-clash-500">Populaire</p>
            </div>
            <div className='plan_left_head bg-blue-tn border-b border-black p-4 text-left'>
                <h2 className='font-clash-700 text-xl xl:text-2xl text-white mb-4'>Creative Identity</h2>
                {/* <div className='flex items-end mb-2 line-through text-gray-tn'>
                    <p className="text-gray-tn text-base xl:text-2xl font-clash-400">1 500 €</p>
                    <span className="text-gray-tn font-clash-400 text-base xl:text-2xl">/mois</span>
                </div> */}
                <div className='h-[32px] xl:h-[40px]'></div>
                <div className='flex items-end mb-2'>
                    <p className="text-4xl xl:text-6xl font-clash-700 text-white">1 500 €</p>
                    <span className="text-gray-tn font-clash-400 text-xl xl:text-3xl">/mois</span>
                </div>

                <p className="text-gray-tn font-clash-400 text-xl xl:text-2xl mb-4">10 mois d’engagement</p>
            </div>  
            <div className='content_services border-b border-black mx-4 mt-8'>
              <ul>
                {servicesCreativeIdentity.map((service, index) => (
                  <ServiceItem key={index} title={service.title} validated={service.validated} info={service.info} />
                ))}
              </ul>
            </div>
          <div className="includes">
            <div className='includes_title'>
              <p className='font-clash-700 text-xl'>Inclut:</p>
            </div>
            <div className='includes_content'>
                <ul className='ml-4'>
                  <li className='font-clash-500'>Moodboard & Direction Artistique</li>
                  <li className='font-clash-500'>Allers-retours illimités pour chaque maquette</li>
                  <li className='font-clash-500'>2 formats déclinés <span className='font-clash-400 text-sm'>(4:5 et 9:16 ou 1:1)</span></li>
                </ul>
            </div>
          </div>
          <div className='mb-8'>
            <Button label="Prendre RDV" styleType="blue" link="https://calendly.com/nestour/appel-decouverte" blank={true} />
          </div>
          <p className="availability relative font-clash-500 color-black lg:mb-6">1 place restante</p>
        </div>
        <div className="plan_right">
            <div className='plan_left_head bg-gray-light-tn border-b border-black p-4 text-left'>
                <h2 className='font-clash-700 text-xl xl:text-2xl text-blue-tn mb-4'>Creative & Motion Identity</h2>
                <div className='h-[32px] xl:h-[40px]'></div>
                <div className='flex items-end mb-2'>
                    <p className="text-4xl xl:text-6xl font-clash-700 text-blue-tn">2 500€</p>
                    <span className="text-gray-tn font-clash-400 text-xl xl:text-3xl">/mois</span>
                </div>
                <p className="text-gray-tn font-clash-400 text-xl xl:text-2xl mb-4">12 mois d'engagement</p>
            </div>
            <div className='content_services border-b border-black mx-4 mt-8'>
            <ul>
              {servicesCreativeMotionIdentity.map((service, index) => (
                <ServiceItem key={index} title={service.title} validated={service.validated} info={service.info} />
              ))}
            </ul>
            </div>
          <div className="includes">
            <div className='includes_title'>
              <p className='font-clash-700 text-xl'>Inclut:</p>
            </div>
            <div className='includes_content'>
                <ul className='ml-4'>
                  <li className='font-clash-500'>Moodboard & Direction Artistique</li>
                  <li className='font-clash-500'>Allers-retours illimités pour chaque maquette</li>
                  <li className='font-clash-500'>4 formats déclinés <span className='font-clash-400 text-sm'>(4:5, 9:16, écran géant et LED)</span></li>
                </ul>
            </div>
          </div>
          <div className='mb-8'>
            <Button label="Prendre RDV" styleType="white" link="https://calendly.com/nestour/appel-decouverte" blank={true} />
          </div>
          <p className="availability relative font-clash-500 color-black mb-6">2 places restantes</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
